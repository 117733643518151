@import 'src/styles/colors.scss';

.combo-box-field-container {
    align-items: center;
    display: flex;
    border-radius: 5px;
    position: absolute;
    z-index: 10;
    border: none;
    cursor: pointer;
}
