@import 'src/styles/colors.scss';

.tools-container {
    background-color: $allocatorComponentBackgroundLightColor;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 30px;
    overflow-y: auto;

    .tools-landing {
        background-color: $allocatorProductBackgroundColor;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 450px;
        margin: auto;
        padding: 20px 24px;
        border: 1px solid $grey;
        border-radius: 4px;

        .tools-tabs {
            display: flex;
            margin: 20px 0;
        }
    }
}
