
.simple-auto-complete-container {
    /* Add your styles here */
}

.MuiAutocomplete-paper {
    min-width: 260px;
}

.auto-complete-menu {
    max-height: 600px;
}
