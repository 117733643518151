@import "src/styles/component";

.user-role-user-role-container {
    height: 360px;
    .items-container {
        color: $primary;
        display: flex;
        flex-direction: column;
        .title {
            font-weight: bold;
            margin-bottom: 10px;
        }
        .data {
            margin-bottom: 20px;
        }
    }
}
