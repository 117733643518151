
.sub-accounts-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 360px;
    .tree {
        &__item {
            .label {
                display: inline-block;
                &__icon {
                    vertical-align: bottom;
                    margin-right: 5px;
                }
            }
        }
    }
}
