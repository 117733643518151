@import 'src/styles/colors.scss';

.allocator-container {
    background-color: $allocatorProductBackgroundColor;
    box-sizing: border-box;
    display: flex;
    height: 100%;
    width: 100%;
    padding: 20px;

    .allocator-container-landing {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        padding: 0 70px;

        h1 {
            color: $black;
            font-weight: 400;
            margin: 0 0 50px;
        }

        .allocator-container-landing-upload {
            height: 300px;
            width: 100%;
            margin-bottom: 50px;
        }
    }

    .allocator-left-container {
        min-width: 250px;
        height: 100%;
        margin-right: 20px;
        display: flex;
        flex-direction: column;

        .allocator-left-container-upload {
            width: 220px;
            margin: 12px 0;
        }

        .allocator-left-container-list {
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            scrollbar-width: thin;
            scrollbar-color: $allocatorContainerBackgroundColor $grey;

            &::-webkit-scrollbar {
                width: 10px;
                height: 10px;
                background-color: $grey;
                border-radius: 2px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $allocatorContainerBackgroundColor;
                border: 1px solid $grey;
                border-radius: 2px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background-color: $white;
            }
        }
    }

    .allocator-right-container {
        width: 100%;
        max-width: calc(100% - 250px);
        padding-top: 20px;

        .reports-container {
            height: 100%;
        }
    }
}

.MuiButtonBase-root.MuiMenuItem-root {
    background-color: $white;
    min-height: 36px;
    min-width: 120px;
    @media (min-width: 600px) {
        min-height: 36px;
    }
}

.MuiButtonBase-root.MuiMenuItem-root.Mui-selected {
    background-color: $allocatorComponentBackgroundColor;
}

.MuiButtonBase-root.MuiMenuItem-root:hover {
    background-color: $allocatorComponentBackgroundLightColor;
}