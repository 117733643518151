@import 'src/styles/colors.scss';

.menu-item-container {
    background-color: $agentMenuItemBackgroundColor;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    color: $textColor;
    padding: 10px;

    &:hover{
        cursor: pointer;
    }
}
