@import "/src/styles/colors.scss";

.pdf-viewer-pages-container {
    display: flex;
    flex-direction: row;
    padding: 10px;
    align-items: center;
    width: 100%;
    background-color: $tritechBlue;


    .pdf-viewer-pages-main {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;

        .page-text {
            color: white;
            font-weight: 600;
            margin-right: 10px;
            background-color: $tritechBlue;
        }

        .pdf-page {
            display: flex;
            align-items: center;
            padding: 4px 8px;
            border-radius: 18px;
            border: 1px solid rgba(74, 94, 126);
            background-color: $tritechBlue;
            color: white;
            margin-left: 5px;
            margin-bottom: 5px;
            width: -moz-fit-content;
            width: -webkit-fit-content;
            width: fit-content;
            max-width: 120px;

            .pdf-page-text {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            &--selected {
                background-color: rgba(74, 94, 126);
                color: white;
            }

            &:hover {
                background-color: white;
                color: $tritechBlue;
                cursor: pointer;
            }
        }
    }
}
