
.tts-empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    height: 100%;
    background-color: white;
    border: 1px dashed lightgray;
    border-radius: 5px;
    padding: 20px;
    justify-content: center;

    &-graphic {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
    }

    &-text {
        text-align: center;
        padding: 10px;
    }

    &-actions {
        padding: 10px;
        display: flex;
    }

}
