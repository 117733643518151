@import 'src/styles/component.scss';

.account-admin-container {
    display: flex;
    height: 100%;
    width: 100%;

    .account-admin-sidebar {
        display: flex;
        flex-direction: column;
        height: 100%;
        color: $light;

        &--collapsed {
            visibility: hidden;
        }

        .title {
            font-size: larger;
            text-align: center;
            padding-bottom: 20px;
        }
        .button {
            color: $light;
            background-color: $indigo_600;
            margin-bottom: 20px;
        }

        .account-list-container {
            padding-bottom: 10px;
        }

    }

    .account-admin-content {
        background-color: $ghost-white;
        scrollbar-color: $indigo_800 $ghost-white;

        .tab-list {
            background-color: $light;
            width: 100%;
            border-bottom: 1px solid #E0E0E0;
            position: fixed;
            z-index: 1;

            .tab {
                font-weight: bold;
                color: $primary;
            }
        }

        .tab-panel {
            margin-top: 40px;
        }

    }

}