
.account-list-container {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;

    .account-list-search-bar-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .account-list-active-filter-container {
        align-items: center;
        display: flex;
    }

    .scrollable-list-container {
        overflow-x: hidden;
        overflow-y: auto;
    }
}
