
$indigo_100: #0b0e13;
$indigo_200: #151b26;
$indigo_300: #202939;
$indigo_400: #2a374c;
$indigo_500: #354560;
$indigo_600: #4e668e;
$indigo_700: #738ab2;
$indigo_800: #a2b1cc;
$indigo_900: #d0d8e5;

$dark_100: #0d0d0d;
$dark_200: #1b1b1b;
$dark_300: #282828;
$dark_400: #353535;
$dark_500: #434343;
$dark_600: #686868;
$dark_700: #8e8e8e;
$dark_800: #b4b4b4;
$dark_900: #d9d9d9;

$ghost-white: #f0eff4;
$white: #ffffff;
$yellow: #ffff00;


// To be exported
$primary: var(--tts-primary);
$secondary: var(--tts-secondary);
$dark: var(--tts-dark);
$light: var(--tts-light);
$light2: $ghost-white;
$accent-light: var(--tts-accent-light);
$accent-dark: var(--tts-accent-dark);
$danger: var(--tts-danger);
$warning: var(--tts-warning);
$success: var(--tts-success);
$info: var(--tts-info);
$transparent: var(--tts-transparent);

