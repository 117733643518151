
@import 'src/styles/colors.scss';

.migration-import-container {
    .modal-header {
        background-color: $tritechBlue;
        border-bottom: 1px solid $white;
        color: $white;
        padding: 12px 0px;
        text-align: center;
        width: 100%;
    }
    .content-container{
        height: 90%;
        padding: 12px;
    }
    .actions-container{
        width: 100%;
        display: flex;
        flex-direction: row;
    }
    .file-picker-input{
        display: none;
    }
    .icon-pointer{
        cursor: pointer;
    }
    .grid-container{
        height: 551px;
        width: 100%;
    }
}