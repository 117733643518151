
.scrollable-note-list-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .flex-container {
        align-items: center;
        display: flex;
        width: 100%;
        height: 100%;
    }

    .note-icon{
        height: 16px;
        width: 16px;
    }
}
