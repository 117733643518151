@import "/src/styles/colors.scss";

.app-bar-container-class {
    border-bottom: 1px solid rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;

    .tab-active {
        min-height: 10px;
        height: auto;
        text-transform: none;
        opacity: 1;
        margin-top: 6px;
        font-weight: bold;
        background-color: rgba(153, 182, 221, 0.2);
        border-radius: 10px 10px 0px 0px;
        border: 1.5px solid rgba(255, 255, 255, 1);
        border-bottom: 0;
        margin-left: 2px;
        margin-right: 2px;
    }

    .tab-unactive {
        min-height: 10px;
        height: auto;
        text-transform: none;
        opacity: 1;
        margin-top: 6px;
        border: 1px solid rgba(255, 255, 255, 0.4);
        border-bottom: 0;
        border-radius: 10px 10px 0px 0px;
        margin-bottom: 0;
        margin-left: 2px;
        margin-right: 2px;
    }

    .close-all-button {
        margin-left: auto;
        margin-right: 10px;
    }

    .close-all-button:hover {
        background-color: rgba(153, 182, 221, 0.2);
    }

    .tab-header {
        display: flex;
        align-items: center;

        .return-node-icon-container {
            align-items: center;
            display: flex;
            margin-right: 6px;
        }

        .return-icon {
            height: 20px;
            width: 20px;
        }

        .tab-name {
            line-height: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 200px;
        }

        .close-button {
            margin-left: 10px;
            color: white;
            display: flex;
            align-items: center;
        }
    }
}
