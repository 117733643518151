@import 'src/styles/colors.scss';

.formsplus-container {
    height: 100%;
    width: 100%;

    .formsplus-document-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        width: 100%;
        
            
        .formsplus-document-header {
            align-items: center;
            display: flex;
            flex-direction: column;
            height: 5%;
            justify-content: center;
            width: 100%;
        }
    
        .formsplus-document {
            height: 100%;
           overflow: auto;
            width: 100%;
        }
    
        .formsplus-error{
            align-items: center;
            color: $black;
            display: flex;
            height: 100%;
            justify-content: center;
            width: 100%;
        }
    }
}
