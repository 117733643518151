@import 'src/styles/colors.scss';

.menu-item-container {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    color: $textColor;
    padding: 10px;

    &:hover{
        background-color: rgb(85,103,133);
        cursor: pointer;
    }

    &.selected {
        background-color: rgb(85,103,133);
    }
}
