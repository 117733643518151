@import 'src/styles/colors.scss';

.report-grid-container {
    height: calc(100% - 112px);
    width: 100%;

    & .editable {
        color: $allocatorGridTextEditableColor;
    }
    & .corrected {
        color: $allocatorGridTextCorrectedColor;
    }
    & .total {
        background-color: $allocatorComponentBackgroundColor !important;
        font-weight: 600;
    }
}
