@import 'src/styles/colors.scss';

.import-premium-data-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background-color: $allocatorContainerBackgroundColor;
    border: 1px solid $grey;
    border-radius: 2px;
    height: 100%;
    min-width: 432px;
    min-height: 200px;
    cursor: pointer;
    margin: 12px 12px 0;

    .import-premium-data-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        p {
            margin: 12px 0 0;
            text-align: center;
        }
    }
}