@import "src/styles/component.scss";

.tts-container-with-resizable-sidebar {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: $primary;

    * {
        box-sizing: border-box;
    }

    .sidebar {
        display: flex;
        flex-direction: row;
        border-right: 1px solid $light;
        box-shadow: 3px 0px 4px 0px rgba(0,0,0,1);

        &--collapsed {
            &:hover {
                .toggle-collapse-button {
                    display: flex;
                    transition: opacity 0.3s ease;
                    opacity: 1;
                    .sidebar__resizer--collapsed {
                        border-right: $spacing-2 solid $indigo_800;
                    }
                }



            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            padding: $spacing-16;
            overflow-x: hidden;
            &--collapsed {
                padding: 0;
            }
          }

        &__resizer {
            flex-grow: 0;
            flex-shrink: 0;
            justify-self: flex-end;


            // To avoid select unwanted text when dragging the resizer
            -webkit-user-select: none; /* Safari */
            -ms-user-select: none; /* IE 10 and IE 11 */
            user-select: none; /* Standard syntax */

            cursor: col-resize;
            resize: horizontal;
            width: $spacing-4;
            border-right: $spacing-2 solid $primary;

            &:hover, &:active {
                transition: 0.3s ease;
                border-right: $spacing-2 solid $indigo_800;
                .toggle-collapse-button {
                    display: flex;
                    transition: opacity 0.3s ease;
                    opacity: 1;
                }
            }

            &--collapsed {
                margin-left: auto;
                cursor: initial;
                border-right: $spacing-2 solid $transparent;
                &:hover {
                    cursor: pointer;
                }
            }

            .toggle-collapse-button {
                position: absolute;
                top: 20%;
                cursor: pointer;
                width: 20px;
                height: 20px;
                background: $secondary;
                border-radius: 50%;
                overflow: hidden;
                padding: 5px;
                margin-left: -6px;
                display: flex;
                transition: opacity 0.3s ease;
                opacity: 0;
                z-index: 10;
                &:hover {
                    background: $indigo_800;
                }
            }
        }
    }

    .content {
        background: $ghost-white;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        width: 100%;
    }

    .toolbar {
        width: 50px;
    }
}
