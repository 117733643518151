@import 'src/styles/colors.scss';

.drag-and-drop-container {
    box-sizing: border-box;
    background-color: $allocatorContainerBackgroundColor;
    border: 1px solid $grey;
    border-radius: 2px;
    width: 100%;
    height: 100%;
    min-height: 116px;
    cursor: pointer;

    .upload-info-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        p {
            margin: 12px 0 0;
            text-align: center;
        }

        .upload-info-container-hint {
            color: $grey;
            font-size: 14px;
            margin-top: 4px;
        }
    }
}