@import 'src/styles/colors.scss';

.folder-node {
    .folder-node-container {
        align-items: center;
        display: flex;
        width: 100%;
      
        .folder-node-name-container {
            color: $textColor;
            width: 90%;
        }
    }
}
