@import "/src/styles/colors.scss";

.pdf-viewer-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: $documentContainerBackgroundColor;
    width: 100%;
    position: relative;

    .pdf-toolbar-container {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 20;
    }
}
