@import 'src/styles/colors.scss';

.return-node-container {
    align-items: center;
    display: flex;
    padding: 8px 0px 8px 32px;
    border-bottom: 1px solid rgba(255, 255, 255, .08);
    &:hover {
      cursor: pointer;
      background-color: #556785;
    }
      
    .return-node-name-container {
        flex-grow: 1;
        color: $textColor;
        overflow: hidden;
        white-space: nowrap;
    }
    .return-node-icon-container{
        align-items: center;
        display: flex;
        min-width: 50px;
    }
    
    .return-icon {
        height: 24px;
        width: 24px;
    }
}

.selected-return-node-container {
    font-weight: 700;
}
  

  