
.general-container {
    .header {
        display: inline;
        font-weight: bold;
        margin: 8px 0px;
    }
    .subheader {
        margin: 0px 8px 0px 0px;
    }
    .file-uploader{
        margin: 10px 0px;
    }
}
