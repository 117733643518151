@import 'src/styles/colors.scss';

.municipal-container {
    height: 100%;
    width: 100%;

    .municipal-document-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        width: 100%;
        
            
        .municipal-document-header {
            align-items: center;
            display: flex;
            flex-direction: column;
            height: 5%;
            justify-content: center;
            width: 100%;
        }
    
        .municipal-document {
            height: 100%;
            overflow: auto;
            width: 100%;
        }
    
        .municipal-error{
            align-items: center;
            color: $black;
            display: flex;
            height: 100%;
            justify-content: center;
            width: 100%;
        }
    }
}
