
.options-container {
    .header {
        font-weight: bold;
        margin: 8px 0px;
    }
    .subheader {
        margin: 0px 8px 0px 0px;
    }
    .amount-container{
        align-items: center;
        display: flex;
        flex-direction: row;
        margin: 8px 0px;
        justify-content: space-between;
    }
}
