
.usage-report-container {
    width: 100%;

    .top-container {
        display: flex;
        height: 20%;
        width: 100%;
    }

    .report-options-container {
        display: flex;
        flex-direction: column;
        margin: 10px 10px;
        text-wrap: nowrap;
        width: 30%;
    }

    .clicks-container {
        width: 70%;
    }

    .data-grid-container {
        height: 70%;
        margin: 10px 10px;
    }
}
