@import "src/styles/colors.scss";

.number-field-container {
    align-items: center;
    display: flex;
    border-radius: 6px;
    position: absolute;
    z-index: 10;

    .number-field-input {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        border: none;
        border-radius: 6px;
    }

    .add-note-icon {
        position: absolute;
        right: -24px;
        opacity: 0;
        transition: opacity 0.3s;
    }

    &:hover .add-note-icon {
        opacity: 1;
        cursor: pointer;
    }
}
