@import 'src/styles/colors.scss';

.label-field-container {
    align-items: center;
    display: flex;
    background-color: $labelFieldBackgroundColor;
    opacity: 0.8;
    position: absolute;
    z-index: 10;
}
