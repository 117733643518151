.data-table-container {
    height: 100%;
    width: 100%;
    overflow: hidden;

    .grid-container {
        position: relative;
        height: 100%;
        width: 100%;
    }

    .grid-right-toolbar-container {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 20;
    }

    .data-table-select fieldset {
        border: none;
        padding: 0;
    }
    .MuiOutlinedInput-input {
        padding-left: 0;
        font-size: 14px;
    }
    .sbp-jurisdiction-select {
        font-weight: 600;
        margin: 10px 0px;
        display: inline-flex;
        margin-left: auto;
        & .MuiOutlinedInput-input {
            padding: 0px 35px 0px 10px;
        }
    }
    .grid-toolbar-container {
        flex-direction: column;
        align-items: flex-start;
        .first-grid-toolbar-row {
            display: flex;
            width: 100%;
        }
    }

    .data-table-no-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 10px;
        width: 100%;
        width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
        width: -moz-available; /* WebKit-based browsers will ignore this. */
    }

    .select-all-rows-button-container {
        left: 1px;
        z-index: 1000;
        width: 20px;
        height: 40px;
        cursor: crosshair;
        background-color: transparent;
        position: absolute;

        .select-all-rows-button {
            width: 20px;
            height: 20px;
            background-color: #000;
            clip-path: polygon(0 0, 100% 0, 0 100%);
        }
    }
}
