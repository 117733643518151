@import "/src/styles/colors.scss";

.floating-window {
    top: 190px;
    position: fixed;
    right: -400px; /* Off-screen initially */
    z-index: 19;
    width: fit-content;
    height: auto;
    max-height: 80%;
    max-width: 90%;
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
    resize: both;
    border: 1px solid rgba(255, 255, 255, 1);
    border-radius: 10px 10px 0px 0px;
    background-color: #fff;
    overflow: auto;

    &.visible {
        right: 50px; /* Slide in from the right */
    }

    .header {
        position: sticky;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: white;
        background-color: $primaryColor;
        border-bottom: 1px solid white;
        box-shadow: 0 20px 50px rgba(1, 1, 1, 0.5);
        z-index: 11;
        padding: 5px 10px;
        flex-shrink: 0;
    }

    .title-container {
        flex-grow: 1;
        display: flex;
        justify-content: center;
    }

    .window-title {
        font-weight: bold;
        font-size: 20px;
    }

    .close-button-window {
        cursor: pointer;
        padding: 5px 10px;
        background-color: rgba(153, 182, 221, 0.3);
        border-radius: 4px;
        color: white;
        display: flex;
        align-items: center;
    }
}
