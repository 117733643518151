@import 'src/styles/colors.scss';

.agent-container {
    height: 100%;
    width: 100%;

    .agent-right-container {
        background-color: $agentRightContainerBackgroundColor;
        display: flex;
        height: 100%;
        padding: 16px;
    }
}