@import "/src/styles/colors.scss";

.pdf-renderer-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: $documentContainerBackgroundColor;
    width: 100%;

    .react-pdf__Page {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .react-pdf__Document {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
    }

    .pdf-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        overflow: auto;
    }

    .pdf-viewer {
        margin-left: auto;
        margin-right: auto;
    }

    .pdf-pages {
        display: flex;
        width: 100%;
    }

    .pdf-and-notes-container {
        display: flex;
        overflow: scroll;
        width: 100%;
        padding: 25px;
        align-items: flex-start;

        .pdf-notes-viewer {
            display: flex;
            flex-direction: column;
            overflow: auto;
            width: 100%;
            min-width: -moz-fit-content;
            min-width: -webkit-fill-available;
            min-width: fit-content;
            height: 100%;
        }

    }
    .loader-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;

        .loader-text {
            text-align: center;
            margin: 10 0;
            color: white;
        }
    }

}
