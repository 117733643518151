$black: #000000;
$white: #ffffff;
$tritechBlue: #354560;

$bodyBackgroundColor: $tritechBlue;
$darkBlue: #1976d2;
$footerBackgroundColor: transparent;
$grey: #cccccc;
$iconColor: $white;
$lightBlue: #64b5f6;
$navbarBackgroundColor: $tritechBlue;
$primaryColor: #354560;
$textColor: $white;

$documentContainerBackgroundColor: #f5f5fa;

// Pdf Input Colors
$fieldInputColor: rgba(17, 18, 255, 0.7);
$checkFieldBackgroundColor: rgba(255, 255, 0, 0.7);
$comboBoxFieldBackgroundColor: rgba(255, 0, 0, 0.7);
$dateFieldBackgroundColor: rgba(0, 128, 0, 0.7);
$labelFieldBackgroundColor: none;

// Text fields
$textFieldOverrideAlwaysBackgroundColor: #cebebe;
$textFieldOverrideNeverBackgroundColor: rgba(153, 182, 221, 0.7);
$textFieldValueColor: #1112ff;
$textFieldOverrideValueColor: #ff1111;
$textFieldUnableBackgroundColor : transparent;

// Premium Tax Colors
$premiumTaxReturnTreeBackgroundColor: transparent;
$premiumTaxContentBackgroundColor: $white;
$premiumTaxDataTableColumnHeaderTextColor: $black;
$premiumTaxDataTableIconColor: #494747;
$premiumTaxDataTableEditableTextColor: #0033a4;

// Forms Plus Colors
$formsPlusReturnTreeBackgroundColor: transparent;
$formsPlusContentBackgroundColor: $white;

// Agent Colors
$agentLeftContainerBackgroundColor: transparent;
$agentRightContainerBackgroundColor: transparent;
$agentMenuItemBackgroundColor: transparent;

// Account Admin
$accountAdminLeftContainerBackgroundColor: transparent;
$accountAdminRightContainerBackgroundColor: $white;
$accountAdminComponentButtonBackgroundColor: #bbbbbb;
$accountAdminComponentButtonTextColor: #2f5597;
$accountAdminStyledHeaderTextColor: #2f5597;
$accountAdminStyledLabelTextColor: $black;
$accountAdminStyledOpenModalButtonTextColor: #68a3a5;
$accountAdminActiveAccountIconColor: $white;
$accountAdminInactiveAccountIconColor: grey;

// Allocator
$allocatorProductBackgroundColor: $white;
$allocatorContainerBackgroundColor: #f4f7fb;
$allocatorSelectedContainerBorderColor: #5b6f90;
$allocatorComponentBackgroundColor: #dbe3f2;
$allocatorComponentBackgroundLightColor: #eef2f9;
$allocatorTextGreyColor: #00000099;
$allocatorGridHeaderBackgroundColor: #beb9b9;
$allocatorGridRowBackgroundColor: #f2f2f2;
$allocatorGridTextEditableColor: #0033A4;
$allocatorGridTextCorrectedColor: red;

:export {
    accountAdminLeftContainerBackgroundColor: $accountAdminLeftContainerBackgroundColor;
    accountAdminRightContainerBackgroundColor: $accountAdminRightContainerBackgroundColor;
    accountAdminComponentButtonBackgroundColor: $accountAdminComponentButtonBackgroundColor;
    accountAdminComponentButtonTextColor: $accountAdminComponentButtonTextColor;
    accountAdminStyledHeaderTextColor: $accountAdminStyledHeaderTextColor;
    accountAdminStyledLabelTextColor: $accountAdminStyledLabelTextColor;
    accountAdminStyledOpenModalButtonTextColor: $accountAdminStyledOpenModalButtonTextColor;
    accountAdminActiveAccountIconColor: $accountAdminActiveAccountIconColor;
    accountAdminInactiveAccountIconColor: $accountAdminInactiveAccountIconColor;
    agentLeftContainerBackgroundColor: $agentLeftContainerBackgroundColor;
    agentMenuItemBackgroundColor: $agentMenuItemBackgroundColor;
    agentRightContainerBackgroundColor: $agentRightContainerBackgroundColor;
    allocatorProductBackgroundColor: $allocatorProductBackgroundColor;
    allocatorContainerBackgroundColor: $allocatorContainerBackgroundColor;
    allocatorSelectedContainerBorderColor: $allocatorSelectedContainerBorderColor;
    allocatorComponentBackgroundColor: $allocatorComponentBackgroundColor;
    allocatorComponentBackgroundLightColor: $allocatorComponentBackgroundLightColor;
    allocatorTextGreyColor: $allocatorTextGreyColor;
    allocatorGridHeaderBackgroundColor: $allocatorGridHeaderBackgroundColor;
    allocatorGridRowBackgroundColor: $allocatorGridRowBackgroundColor;
    allocatorGridTextEditableColor: $allocatorGridTextEditableColor;
    allocatorGridTextCorrectedColor: $allocatorGridTextCorrectedColor;
    black: $black;
    bodyBackgroundColor: $bodyBackgroundColor;
    checkFieldBackgroundColor: $checkFieldBackgroundColor;
    comboBoxFieldBackgroundColor: $comboBoxFieldBackgroundColor;
    dateFieldBackgroundColor: $dateFieldBackgroundColor;
    darkBlue: $darkBlue;
    fieldInputColor: $fieldInputColor;
    footerBackgroundColor: $footerBackgroundColor;
    formsPlusContentBackgroundColor: $formsPlusContentBackgroundColor;
    formsPlusReturnTreeBackgroundColor: $formsPlusReturnTreeBackgroundColor;
    grey: $grey;
    iconColor: $iconColor;
    labelFieldBackgroundColor: $labelFieldBackgroundColor;
    lightBlue: $lightBlue;
    navbarBackgroundColor: $navbarBackgroundColor;
    premiumTaxReturnTreeBackgroundColor: $premiumTaxReturnTreeBackgroundColor;
    premiumTaxDataTableColumnHeaderTextColor: $premiumTaxDataTableColumnHeaderTextColor;
    premiumTaxDataTableIconColor: $premiumTaxDataTableIconColor;
    premiumTaxDataTableEditableTextColor: $premiumTaxDataTableEditableTextColor;
    primaryColor: $primaryColor;
    textColor: $textColor;
    textFieldOverrideAlwaysBackgroundColor: $textFieldOverrideAlwaysBackgroundColor;
    textFieldOverrideNeverBackgroundColor: $textFieldOverrideNeverBackgroundColor;
    textFieldValueColor: $textFieldValueColor;
    textFieldOverrideValueColor: $textFieldOverrideValueColor;
    textFieldUnableBackgroundColor : $textFieldUnableBackgroundColor;
    white: $white;
    documentContainerBackgroundColor: $documentContainerBackgroundColor;
}
