
.fixed-width-container {
    .item {
        align-items: center;
        display: flex;
        flex-direction: row;
        margin: 8px 0px;
        width: 50%;
    }
    .subheader {
        font-weight: bold;
        width: 50%;
    }
    .field {
        text-overflow: ellipsis;
        width: 50%;
    }
    .text-input {
        width: 50%;
    }
    .error-message {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        width: 100%;
    }  
}
