.container {
    display: flex;
    position: relative;
}

.synchronized {
    /* Add any specific styles for synchronized state */
}

.desynchronized {
    width: 100%;
    height: 100%;
}

.main-tab {
    flex: 1;
}

.split-tab {
    flex: 1;
    width: 50%;
    border-left: 5px solid #354560;
}

.sync-button {
    background-color: #354560;
    border: 1px solid #354560;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    position: absolute;
    top: 150px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
}

.sync-icon {
    background-color: #354560;
    color: white;
}
