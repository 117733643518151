.spaced-text-container {
    position: absolute;
    cursor: text;
    /* Add any common styles for the container here */
}

.spaced-text-char {
    position: absolute;
    font-family: monospace;
    background-color: transparent;
    /* Add any styles for the individual characters here */
}
