
@import 'src/styles/colors.scss';

.payment-request-export-container {
    .modal-header {
        background-color: $tritechBlue;
        border-bottom: 1px solid $white;
        color: $white;
        padding: 12px 0px;
        text-align: center;
        width: 100%;
    }
    .content-container{
        height: 90%;
        padding: 12px;
    }
    .action-buttons-container {
        display: flex;
        justify-content: center;
        margin: 12px 0px;
    }
}
