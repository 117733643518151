
.checkboxtree {
    min-height: 400px;
    height: 400px;
    max-height: 400px;
    overflow-y: scroll;
    margin: 20px 0;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    box-sizing: border-box;
    scrollbar-width: none;
}
